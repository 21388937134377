@import "../../assets/scss/mixin";

.panel-wrapper {
  .panel-main-content {
    margin-left: 240px;
    width: calc(100% - 240px);
    padding: 0 0 32px;
    position: relative;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;

    @media screen and (max-width:$breakpoint_tablet) {

      &.hide {
        margin-left: 0;
        width: 100%;
      }

      margin-left: 0;
      width: 100%;
    }

    // .panel-header {
    //   width: inherit;
    //   height: 40px;
    //   background: #fff;
    //   padding: 0 25px;
    //   position: fixed;
    //   z-index: 99;

    //   @media screen and (max-width:$breakpoint_tablet) {
    //     padding: 0 15px;
    //   }
    // }

    .side-menu-btn {
      background: transparent;
      position: fixed;
      left: 15px;
      top: 22px;
      border: 2px solid #e9e9e9;
      border-radius: 8px;
      @include transition;
      display: none;
      z-index: 999;

      @media (max-width: $breakpoint_tablet) {
        display: block;
      }

      img {
        width: 17px;
        height: 23px;
      }
    }

    .panel-main-wrapper {
      // min-height: calc(100vh - 108px);
      padding: 20px 25px 0;
      // margin-top: 40px;

      @media screen and (max-width:$breakpoint_tablet) {
        padding: 25px 15px 0;
      }

      .panel-card {
        margin: 0 auto 70px;
        width: 100%;

        .panel-body {
          background: #F8F8F8;
          box-shadow: 20px 20px 11px rgba(0, 0, 0, 0.01), 11px 11px 9px rgba(0, 0, 0, 0.05), 5px 5px 7px rgba(0, 0, 0, 0.09), 1px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          padding: 24px 16px;
        }
      }
      .loader {
        border: 4px solid #f3f3f3;
        border-radius: 50%;
        border-top: 4px solid #5E196C;
        width: 60px;
        height: 60px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
        position: absolute;
        top: 50%;
        right: 45%;
        transform: translate(-50%, -50%);
      }
      
      /* Safari */
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
  }
}

.table-btn {
  display: inline-block;
  margin: 2px 0 2px 5px;
  background: var(--theme-color);
  font-size: 12px;
  border-radius: 8px;
  border: none;
  letter-spacing: 1px;
  white-space: nowrap;
  color: var(--white-color);
  line-height: 30px;
  padding: 0 15px;
  @include transition;

  i {
    margin-right: 5px;
  }

  &:hover {
    color: #ffffff !important;
    background: var(--theme-color);
  }
}

.header-search {
  position: relative;
  margin-right: 10px;
  width: 275px;

  input {
    width: 100%;
    height: 40px;
    font-weight: 500;
    padding-left: 12px;
    padding-right: 38px;
    outline: none;
    background: #ffff;
    border: 2px solid #EFEEF1;
    border-radius: 8px;
    font-size: 14px;
    color: #8A8A8A;

    // &::placeholder {
    //   color: #8A8A8A
    // }
  }

  // button {
  //   background: #0A2558;
  //   border: none;
  //   padding: 0;
  //   width: 32px;
  //   height: 32px;
  //   position: absolute;
  //   right: 4px;
  //   top: 4px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 4px;

  //   img {
  //     width: 20px;
  //   }
  // }
}