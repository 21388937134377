label{
  color:black;
}

.dropdown-style {
  width: 100%;
  min-height: auto;
  padding: 9px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
  /* box-shadow: 4px 4px 4px gray; */
}

.form-container {

  width: 100%;
  height: 100%;
  padding: 20px;
  /* background: linear-gradient(to right top, rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234)); */
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 768px) {
  .form-container {
    grid-template-columns: 1fr;
  }
  
}
.left-panel {
  width: 100%;
  height: 100%;
  display: flex;
justify-content: center;
align-items: center;
  overflow-y: auto;
  border-radius: 8px;
  padding: 20px;
  /* background-image: radial-gradient(circle at 10% 20%, rgb(28, 142, 210) 0%, rgb(113, 192, 244) 47.3%, rgb(139, 238, 230) 88.4%); */
background-color: rgb(234, 234, 234);
  backdrop-filter: blur(4px);
}

.right-panel {
  width: 100%;
  /* height: 100%; */
  border-radius: 8px;
  padding: 20px;
  background-image: radial-gradient(circle at 10% 20%, rgb(175, 218, 244) 0%, rgb(194, 230, 254) 47.3%, rgb(184, 245, 240) 88.4%);
  overflow: auto; /* Ensure the response window doesn't overflow */
}

form {
  max-width: 400px;
  margin: auto;
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

label {
  display: block;
  margin-bottom: 5px;
  color: black;
}

input[type="text"],
textarea {

  font-size: 14px !important;
  width: 100%;
  background-color: white;
  padding: 12px;
  border: none; /* Ensure all input fields and textarea have a consistent border */
  border-radius: 5px; /* Optionally add border-radius for rounded corners */
  
}
textarea::placeholder{
  font-size: 11px;
}


input[type="text"]:focus,
textarea:focus {
  outline: none;
  border-color: #edf1f4;
}
.option-card{
  box-shadow: 4px 4px 4px gray;
}
#submit-btn {
  background: rgb(92, 92, 252);
  padding: 8px 25px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background-color:  rgb(78, 78, 253);
  }
}

button:hover {
  background: linear-gradient(to right, #0e639c, #3cb0fd);
}

.response-window {
  max-width: 100%;
  padding: 20px;
  background-color: white;
opacity: 0.8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto !important;
  height: calc(100vh - 80px);
}
/* .bg-panal{
  position: relative;
  background-image: radial-gradient(circle at 10% 20%, rgb(198, 227, 243) 0%, rgb(197, 229, 250) 47.3%, rgb(209, 244, 241) 88.4%);

}
.bg-image{
  position: absolute;
  background-color: #d14b12;
  height: 100px;
  width: 100px;
} */

/* Additional styling */
.autocomplete-container {
  position: relative;
  margin-bottom: 15px;
}

.autocomplete-input {
  width: 100%;
  padding: 9px;
  border: 1px solid #ccc;
  border-radius: 5px;
 

}

.autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  box-shadow: 4px 4px 4px gray;

}

.autocomplete-item {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #f5f5f5;
}

/* Media Query for Mobile View */
@media (max-width: 667px) {
  .container {
    flex-direction: column;
  }

  .left-panel,
  .right-panel {
    width: 100%;
  }

  .response-window {
    height: auto; /* Adjust the height for mobile view */
  
  }
}