.container-for-cards {
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
 /* background: linear-gradient(to right top, rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234)); */
  height: 100%;
  overflow-y: auto;
}

.all-card {
  position: relative;
  /* max-width: 290px; */
  height: 170px; 
  cursor: pointer;
  padding: 6px;
  border: 2px solid #ccc;
  border-radius: 18px;
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s, box-shadow 0.3s, border 0.3s, background-color 0.7s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  background-color:rgb(247, 246, 246);
  backdrop-filter: 4px;
}

.all-card:hover {
  background-color: rgb(255, 255, 255,0.8);
  /* transform: scale(1.01); */
  /* box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2); */
  border: 2px solid #4169E1;
  /* background-image: linear-gradient(to top, rgb(254, 255, 255) 0%, #ccd9f3 100%); */
  


}
.all-card:hover .icon {
  color: #4169E1; /* Color when card is hovered */
}
.dividerhover{
  height: 3px;
  background-color:#4169E1;
  margin: 10px auto;
}
.descriptionhover{
  font-size: 12px;
  color: #4169E1;
}
/* Icon styles */
.icon {
  /* position: absolute; */
  top: 4px;
  left: 1px;
 /* display: flex;
  flex-direction: column; 
  align-items: flex-start;
  justify-content: start; */
  font-size: 20px;
   
  
 
}

.title {
  font-size: 18px;
  font-weight:400;
  margin-top: 5px; /* spacing after the icon */
  display: flex;
  align-items: start;
  justify-content: space-between;
}

/* Arrow icon with hover effect */
.arrow-icon {
  margin-top: -10px;
  font-size: 18px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(-45deg); /* Rotate to be between right and upper on hover */
}

.divider {
  height: 3px;
  background-color: #b1adad;
  margin: 10px auto; /* centered divider */
}

.description {
  font-size: 10px;
  margin-top: 0px;
  text-align: start;
  color: rgb(79, 78, 78);
 
}
.top-title{
  font-size: 25px;
  color: black;
  font-weight: bold;
  grid-column:span 4;
  margin-bottom: 15px;
  margin-top: 5px;
}
@media (max-width: 768px) {
  
  .container-for-cards {
   height: 100% !important;
/* Stack cards vertically */
    gap: 10px;
    padding-left: 0; /* Remove padding for mobile */
    grid-template-columns: 1fr;
  }
  .top-title{
    grid-column: span 1;
  }


  .all-card .title {
    font-size: 1.2rem; /* Slightly smaller font for mobile */
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .arrow-icon {
    font-size: 16px; /* Reduce arrow icon size for mobile */
    margin-left: 5px; /* Adjust margin for mobile view */
  }

  .divider {
    height: 2px; /* Thinner divider for mobile */
    background-color: #b1adad;
    margin: 8px auto;
  }

}

