
.card-layout{
  grid-column: span 1;
}
.createCard {
  .card {
    background-image: linear-gradient(to top, #E8E8FE 85%, #FFFFFF);
    border-radius: 10px;
    padding-top: 30px;

    .row {
      margin-bottom: 40px;
      margin-top: 20px;

      .col-xl-6 {
        width: 70%;
        position: relative;

        h3 {
          text-align: center;
          margin-top: 10px;
          font-weight: bold;
          font-size: xx-large;
          margin-bottom: 15px;
        }

        .input-group {
          border: none;
          display: flex;
          flex-direction: column;

          input {
            padding: 10px;
            margin-bottom: 10px;
            background-color: #F5F5FB;
            border-radius: 8px;
            height: 65px;
            border: none;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              margin: 5px 0;

              a {
                text-decoration: none;
                color: black;
                padding-left: 20px;
                display: block;

                &:hover {
                  background-color: #f0f0f0;
                }
              }
            }
          }

          .start {
            margin-top: -106px;
            margin-right: 20px;
            align-self: flex-end;
            padding: 10px 15px;
            border: none;
            width: 100px;
            background-color: #CDCDFF;
            color: white;
            cursor: pointer;
            display: flex;
            align-items: center;
            position: absolute;
            top: 40px;
            right: 0;
            border-radius: 8px; // Ensure this applies to all corners
            overflow: hidden;
          
            img {
              margin-left: 5px;
            }
          
            &:hover {
              background-color: #e6e6f5 !important;
            }
          }
          
        }

        .cards {
          display: grid;
          gap: 20px;
          margin-top: 20px;
          margin-left: 0;
          padding-left: 0;

          .mini-card {
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            text-decoration: none;
            height: 90px;
            width: 100%; // Set width to 100% to be responsive within the grid
            overflow: hidden; // Ensure no overflow

            h4 {
              margin: 0 0 10px 0;
              color: #333;
              white-space: nowrap; // Prevent h4 text overflow
              overflow: hidden;
              text-overflow: ellipsis; // Add ellipsis if text is too long
              font-size: 16px;
            }

            p {
              margin: 0;
              color: #858585;
              white-space: nowrap; // Prevent paragraph text overflow
              overflow: hidden;
              text-overflow: ellipsis; // Add ellipsis if text is too long
            }

            &:hover {
              background-color: #e0e0e0;
            }
          }
        }
      }
    }
  }

  // Media Queries for responsive design
  @media (max-width: 576px) {
    // Mobile (small screens)
    .cards {
      grid-template-columns: 1fr; // One card per row
    }
  }

  @media (min-width: 577px) and (max-width: 768px) {
    // Small devices (sm)
    .cards {
      grid-template-columns: 1fr; // One card per row
    }
  }

  @media (min-width: 769px) and (max-width: 992px) {
    // Medium devices (md)
    .cards {
      grid-template-columns: repeat(2, 1fr); // Two cards per row
    }
  }

  @media (min-width: 993px) {
    // Large devices (lg and up)
    .cards {
      grid-template-columns: repeat(2, 1fr); // Two cards per row
    }
  }
}
@media screen and (max-width: 768px) {
  .card-description{
    display: hidden;
  }
  .card-layout{
    grid-column: span 2;
  }
  
}