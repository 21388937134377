/* src/components/Profile.module.css */
.profileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to right, #1c8ed2, #8beee6);
}



.uploadBtn, .editBtn {
background-color: blue;
  padding: 10px 15px;
  margin-right: 20px;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  /* transition: background 0.3s ease; */
}



.cancel-btn {
background-color: blue;
  padding: 10px 15px;
  margin-right: 100px;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.saveBtn:hover, .cancelBtn:hover {
 background-color: blue;
}




.card {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  border: 2px solid rgb(111, 111, 238); /* Added this line */
}


.title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.userInfo {
  text-align: left;
}

.userInfo p {
  margin: 10px 0;
  font-size: 1.1em;
  color: #555;
}

.label {
  font-weight: bold;
  color: #333;
}

.loading {
  font-size: 1.5em;
  color: #555;
}

.formGroup {
  margin-bottom: 15px;
}

.input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
