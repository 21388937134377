.messageBox {
    width: 100%;
    height: 100vh;
    padding: 20px;

    .logo {
        background-color: #4169E1;
        color: #fff;
        border: 2px solid #4169E1;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 700;
        margin: 0 auto 20px;
    }

    h4 {
        font-size: 20px;
        margin: 20px 0;
        text-align: center;
    }

    .responses-container {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 215px);
        height: calc(100vh - 205px);
        overflow-y: auto;
        padding: 15px;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        background-color: #f7f7f7;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ccc;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #888;
        }
    }

    .response {
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin-top: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(149, 148, 148, 0.1);
        word-wrap: break-word;

        &.user {
            font-size: 12px;
            color:black;
            display: inline-block;
            background-color: #e1f5fe;
            border-right: 4px solid #03a9f4;
            text-align: end;
            align-self: flex-end;
            margin-left: 60px;
            flex-direction: row-reverse;
        }

        &.ai {
            font-size: 12px;
            color:black;
            display: inline-block;
            background-color: #e8f5e9;
            border-left: 4px solid #4caf50;
            align-self: flex-start;
            margin-right: 60px;
        }

        .chat-message-inner {
            display: inline-block;
            text-align: left;
            width: auto;
            max-width: 100%;

            p {
                margin: 0;
                padding: 0;
                word-break: break-word;
            }
        }
    }


    .input-group {
        display: flex;
        align-items: center;
        margin-top: 20px;

        input {
            flex: 1;
            padding: 15px;
            border: 1px solid #D5D5D5;
            border-radius: 8px;
            height: 43px;
            margin: 0;
            transition: border-color 0.3s;

            &:focus {
                border-color: #4169E1;
                outline: none;
            }
        }

        .button-group {
            display: flex;
            align-items: center;

            button.start {
                margin-left: 10px;
                border: none;
                outline: none;
                background: #4169E1;
                color: #fff;
                padding: 10px 20px;
                border-radius: 8px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 16px;
                    height: 16px;
                }

                &:hover {
                    background: #365cbf;
                }
            }

            button.clear-chat {
                margin-left: 10px;
                background-color: #fff;
                border: 1px solid #FF6B6B;
                color: #FF6B6B;
                padding: 8px 16px;
                border-radius: 8px;
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;

                &:hover {
                    color: #fff;
                    background: #FF6B6B;
                }
            }
        }
    }

    .info {
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: #666;
    }
}