@import "../../assets/scss/mixin";

.user-icon{
  // background-color: #000;
  border-radius: 100px;
  margin-top: 3.5px;
  margin-right: 5px;
  font-size: 18px;
  color:#6d6c6c;
}

.logo {
  display: flex;
  align-items: flex-start;

}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-container {
  display: flex;
  align-items: center;
  // padding-right: 40px;
  border: 1px solid red;
  // background-color: rgb(251, 234, 234);
  
}

.logo-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  overflow: hidden;
  border-radius: 50%; /* Makes the container round */
  
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
}

.user-name {
  margin-left: 0px; /* Adjust the spacing as needed */
}

.user-name p {
  text-align: center;
  margin: 0;
  color: #000; /* Adjust the color as needed */
  font-size: 16px; /* Adjust the font size as needed */
}





.logo {
  display: inline-block;
  color: #4169E1;
  font-size: 50px; // Ensures the logo behaves like an inline element
}

.logo-link {
  text-decoration: none;
  padding: 0 25px 0 25px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.logo-image-container {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  // border: 1px solid black;

  // Adjustments for the image
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; // Maintain aspect ratio and cover the container
  }
}
.logo{
  font-size: 25px;
  font-weight: 700;
  height: 40px;
}
.panel-sidebar {
  display: $hidden;
  z-index: 99;
  width: 240px;
  height: 100vh;
  background: #fff;
  border-right: 1px solid #EAEDF3;
  // position: fixed;
  // left: 0;
  // bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  @include transition;

  @media screen and (max-width:$breakpoint_tablet) {

    &.show {
      width: 240px;
      top: 76px;
    }

    &.hide {
      width: 0px;
      padding: 0;
      overflow: hidden;
      top: 76px;
    }
  }
.nav-items{
  font-size: 10px;
  font-weight: 500;
  color:black
}

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: .3rem;
  }

  .ps-sidebar-root {
    border-color: transparent;
    width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .ps-sidebar-container {
    background-color: transparent;
    overflow: inherit;
    .logo{
      display: flex;
      align-items: center;
      padding: 20px;
      justify-content: center;
      border-bottom: 1px solid #EAEDF3;
      @media (max-width: $breakpoint_tablet) {
        padding: 0 20px 20px;
      }
      h5{
        margin: 0 0 0 10px;
        word-break: keep-all;
        font-size: 16px;
      }
    }
  }

  ul {
    padding: 20px;
    .chatbot{
      background-color: #4169E1;
      border-radius: 10px;
      font-size: 14px;
      padding: 10px;
      font-weight: 600;
      margin-bottom: 20px;
      color: white;
      border: 0;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
      &:hover{
        background-color: #315fe7;

      }
      img{
        width: 10px;
        margin-left: 10px;
      }
    }
    &.main-menu {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    .ps-submenu-root {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 5px;

      &.active-link {
        background-color: #4169E1;
          border-radius: 8px;
.nav-icon{
  color: white;
  &:hover{
    color: black;
  }
}
        img {
          // filter: none;
        }
        p{
          color: white;
          font-weight: 600;
        }
      }

      &:hover {
        background-color: #fff;
          border-radius: 8px;

        img {
          // filter: none;
        }
      }

      &:last-child {
        margin-top: 0;
        margin-bottom: 0;
      }

      .ps-submenu-content {
        margin-top: 16px;

        li {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .ps-menu-button {
        padding-left: 30px;
        padding-right: 0px;
        height: auto;
      
       
        span{
          display: flex;
        }

        &:hover {
          background-color: rgb(209, 207, 207);
          border-radius: 8px;
          .nav-icon{
            color: #213469;
          }
          p{
            color: #213469;
            font-weight: 600;
          }
        }

      }

      .ps-submenu-expand-icon {
        margin-right: 5px;
      }

      a {
        display: flex;
        font-size: 16px;
        align-items: center;
        color: #0A2558;
        padding: 8px;
        font-weight: 500;
        white-space: nowrap;
        @include transition;

        .ps-menu-icon {
          margin-right: 0;
        }

        img {
          width: 16px;
          filter: contrast(0.1);
          margin-right: 10px;
        }
        p{
          margin: 0;
          font-size: 14px;
          color: #7B8289;
          font-weight: 600;
        }

        &.ps-menu-icon {
          justify-content: flex-start;
        }
      }
    }
  }
  .hamburger-menu {
    display: none;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 101;
    font-size: 24px;
    cursor: pointer;
    background: #ffffff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
.menu-item 
{
  margin-left: 0px;
}
  
@media screen and (max-width: 768px) {
  .sidebar {
    display: none; /* Hide sidebar by default */
  }

  .sidebar.visible {
    display: block; /* Show sidebar when toggled */
    position: absolute;
    z-index: 100;
    width: 240px;
    height: 100vh;
    background-color: #ffffff;
    box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
  }

  .hamburger-menu {
    display: block; /* Show hamburger menu on small screens */
  }
}

}
@media screen and (max-width: 768px) {
  .panel-sidebar{
    width: 70px;
  
  }
  .logo{
    display: hidden;
  }
  
}

