.voice {

  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 20px;
    .title{
        display: flex;
        flex-direction: column;
        text-align: start;
        align-items: flex-start;
        margin-bottom: 30px;
        h4{
            font-size: 32px;
            margin-bottom: 5px;
        }
        p{
            font-size: 12px;
            color: #898989;
            margin: 0;
            font-weight: lighter;
        }
    }
    .top_tab_bar ul {
      list-style: none;
      padding: 0;
      display: flex;
      margin-bottom: 20px;
      border-bottom: 1px solid #D5D5D5;
    }
  
    .top_tab_bar li {
      margin-right: 5px; // Space between tabs
    }
  
    .top_tab_bar li {
      padding: 3px 15px;
      border: none;
      background: #fff;
      color: #898989;
      cursor: pointer;
      &:hover, &.active {
        background: #babaffa6;
        color: #4169E1;
        border-bottom: 2px solid #4169E1;
      }
    }
  
   .voicesTab{
        .card{
            padding: 10px;
            border: 2px solid #4169E1;
            margin-top: 30px;
            h4{
                font-size: 18px;
                font-weight: 500;
                display: flex;
                align-items: center;
                margin-bottom: 0;
                img{
                    margin-right: 10px;
                }
            }
            p{
                color: #898989;
                margin-bottom: 0;
            }

        }
        .addVoice{
            display: flex;
            align-items: center;
            justify-content: star;
            gap: 15px;
            margin: 30px 0;
            @media screen and (max-width: 600px){
              flex-direction: column;
              align-items: flex-start;
            }
            .content{
                h4{
                    font-size: 18px;
                    margin: 0;
                    font-weight: 500;
                }
                p{
                    font-size: 16px;
                    color: #898989;
                    margin: 0;
                }
            }
            .add{
              font-size: 14px;
              font-weight: 500;
                background-color: #4169E1;
                outline: 0;
                border: 0;
                border-radius: 8px;
                color: white;
                padding: 8px 20px;
                &:hover{
                    background-color: #2e5ad7;
                    
                }
            }
        }
        .info{
            font-size: 16px;
                    color: #898989;
                    margin: 0;
        }
   }
  .voicesList{

  }
  .checkbox{
    width: 20px;
  }
  .list{
 
    display: flex;
    justify-content: left;
    gap: 10px;
    // border: 2px solid #4169E1;
  
  }
    .tab-content p, .tab-content ul {
      margin-top: 0;
    }
  
    .tab-content {
      margin-top: 10px;
      padding: 5px 10px;
    }
  
    .tab-content ul {
    
    
    }
    .deleteSelected{
      font-size: 14px;
      font-weight: 500;
      background-color: #f44336;
      color: white;
      border-radius: 8px;
      border: none;
      padding: 8px 20px;
    }

ul {
  list-style-type: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 5px;
    // border: 1px solid #ccc; // Adjust the width of the border here
    border-radius: 4px;
    
    .delete {
      
      background-color: #f44336;  
      color: white;
      align-items: center;
      // margin-right: 150px;
      border: none;
      border-radius: 4px;
      padding: 3px 10px;
      
      cursor: pointer;
      margin-top: -1px;
    }
  }
}

  }
  