.dashboard-content{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns:1fr 1fr ;
  gap: 20px;
  
}
@media screen and (max-width:1080px) {
  .dashboard-content{
    
    grid-template-columns:1fr  ;
    gap: 20px;
    
  }
  .dashboard-main{
    overflow-y: auto;
  }
  
}
.dashboard-main{
  overflow: hidden;
}
.metrics{
  display: flex;
flex-direction: row;
  gap: 6%;
  margin-left: 50px;
 @media screen and (max-width:1300px) {
  flex-direction: column;
 justify-content: end;
 }
  }
  .chart-container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 25px;
    @media screen and (max-width:1200px) {
      grid-template-columns: repeat(1,1fr);
    }
  }

.container {
    display: flex;
    flex-direction: row; // Default to row layout
   
  
    width: calc(100% - -10px); // Adjusting for the left and right margins
  gap: 2px;

  
    // Responsive Design
    @media (max-width: 768px) { // For medium screens and below
      flex-direction: column; // Stack items vertically
      margin-right: 0; // Adjust margin for better alignment
      .chart-container,
      .score-container {
      margin-left: 17px;
      width: 100%;
      }
    }
  
    .chart-container,
    .score-container {
      padding: 0; // Remove default padding
     
    //   width: 100%; // Ensure full width for both containers
        // Add margin to right for larger screens
  
      // Optionally adjust margins for better spacing in the column layout
      @media (min-width: 768px) {
        margin-left: 20px; // Add margin to left for larger screens
        // Add margin to right for larger screens
      }
    }
  }
  








