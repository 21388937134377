// No Content Styling
.no-content-div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-content {
  font-size: 80px;
  font-weight: bold;
  background-color: #4169e1;
  padding: 40px;
  border-radius: 8px;
  color: white;
}
.bank-container{
  // background: linear-gradient(to right top, rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234));
min-height: 100vh;
}
.copy-text {
  font-size: 14px;
  color: #000000;
  background-color: white;
  width: 100px;
  padding: 20px;
  border-radius: 8px;
  margin-left: 0px;
  top: 0;

left: -60px;
  font-weight: bold;
  position: absolute; /* Ensure it overlays properly */
  animation: zoomFade 1s forwards;
}

@keyframes zoomFade {
  0% {
    opacity: 0;
    transform: scale(0.2); /* Start small */
  }
  50% {
    opacity: 1;
    transform: scale(1.2); /* Zoom in */
  }
  100% {
    opacity: 0;
    transform: scale(1); /* Back to normal size */
  }
}

// Bank Card List
.bank-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 46px;
  padding: 16px;
  max-height: 100vh; /* Limit height for scrolling */
  overflow-y: auto; /* Enable scrolling */
  
  /* Scrollbar Styling */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 26px;
    padding: 16px;
   
    // min-height: 100vh;
  }
  
  .bank-card {
    cursor: pointer;
    width: 100%; /* Ensures the cards have a consistent width */
    flex: 0 0 400px; /* Ensures the flex item does not grow or shrink */

  }
  
  .bank-card-content {
    background-color:rgb(236, 234, 234);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 16px;
    height: 198px; /* Fixed height for the card */
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

// Bank Card
.bank-card {
  cursor: pointer;
 
  width: 400px; /* Consistent card width */
  flex: 0 0 400px; /* Prevent growing/shrinking */
}

.bank-card-content {
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid lightgray;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 16px;
  height:200px; /* Fixed height */
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;

  &:hover {
   border:2px solid rgb(115, 115, 251);
  }
  &.bank-title:hover{
color:rgb(115, 115, 251);
  }
}

// Bank Title
.bank-title {
  font-size: 17px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

// Bank Description
.bank-description {
  font-size: 10px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; /* Limit text to 5 lines */
  line-height: 0.5;
}

// Media Queries
@media (max-width: 768px) {
  .bank-card {
    width: 100%; /* Full width on smaller screens */
    max-width: 300px;
    flex: 0 0 100%;
  }
}

// Common Scrollbar Styles
.scrollbar-container {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}
