.chatBox{
    width: calc(100% - 280px);
    margin-right: 280px;
    @media screen and (max-width: 1024px) {
        width: 100%;
        margin-right: 0;  
        }
    .chat{
       width: 100%;
        .user1{
            max-width: 500px;
            display: block;
            text-align: end;
            margin-top: 10px;
            margin-left: auto;
            p{
                padding: 10px;
                border-radius: 10px;
                font-size: 14px;
                background-color:#1C64F1;
                color: #fff;
                display: inline-block;
                &:last-child{
                    margin: 0;
                }
            }
        }
        .user2{
            max-width: 500px;
            display: block;
            margin-top: 10px;
            margin-right: auto;
            p{
                padding: 10px;
                border-radius: 10px;
                background-color:#EFF7FE;
                font-size: 14px;
                display: inline-block;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
    .search{
        position: relative;
        width: 100%;
        background: white;
        .input-group{
            margin-top: 10px;
            input{
                margin: 0;
                color: black;
                width: 100%;
                padding: 15px;
                border: 1px solid #D5D5D5;
                background: white;
                border-radius: 10px !important;
                height: 52px;
            }
            button{
                position: absolute;
                right: 10px;
                border: 0;
                outline: none;
                background: #D9D9D9;
                padding: 10px 10px 4px;
                border-radius: 8px !important;
                top: 50%;
                transform: translate(0px, -50%);
            }
        }
    }
}
.sideBar{
    z-index: 99;
    width: 280px;
    height: 100vh;
    background: #F3F3F3;
    border-left: 1px solid #EAEDF3;
    position: fixed;
    right: 0;
    padding: 20px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    @media screen and (max-width: 1024px) {
        display: none;   
        }
    .input-group{
        margin-top: 10px;
        input{
            margin: 0;
            color: black;
            width: 100%;
            padding: 10px;
            border: 1px solid #D5D5D5;
            background: transparent;
            border-radius: 8px !important;
            height: 50px;
        }
        button{
            position: absolute;
            right: 5px;
            border: 0;
            outline: none;
            background: transparent;
            padding: 10px;
            border-radius: 8px !important;
            top: 50%;
            transform: translate(0px, -50%);
        }
    }
    .scrollDiv{
        margin-bottom: 120px;
        .box{
            h4{
                font-size: 12px;
                margin: 20px 0 10px;
                color: #909090;
                font-weight: normal;
            }
            a{
                display: block;
                color: #000;
                font-size: 14px;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .upgrade{
        position: fixed;
        bottom: 0px;
        background-color:#F3F3F3 ;
        width: 100%;
        a{
            display: flex;
            align-items: center;
            color: #000;
            margin: 10px 0;
            font-weight: 600;
            img{
                margin-right: 10px;
                border: 1px solid #D5D5D5;
                border-radius: 100px;
                width: 45px;
                padding: 10px;
                height: 45px;
            }
        }
    }
}