/* Button to open the modal */
.open-modal-btn {
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.open-modal-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  border: 2px solid white;
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  width: 100%;
  
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
  z-index: 1001;

  animation: fadeIn 0.3s ease-out;
}

/* Smooth fade-in animation for the modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Modal heading */
.modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #333;
}

/* Form elements */
.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Input fields */
.modal-content input[type="text"],
.modal-content input[type="file"] {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.modal-content input[type="text"]:focus,
.modal-content input[type="file"]:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Modal actions (Submit and Close buttons) */
.modal-actions {
  display: flex;
  justify-content: space-between;
}

.modal-actions {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-actions button1[type="submit"] {
  font-size: 14px;
  font-weight: 500;
  background-color: #5e42fa;
  color: white;
  padding: 2px 15px;
  border-radius: 8px;
  border: none;
  &:hover{
    background-color: #3dd160;
  }
}

.modal-actions button[type="submit"]:hover {
  background-color: #5e42fa;
  padding: 8px 15px;
  transform: scale(1.05);
}

.modal-actions button1[type="button"] {
  background-color: #5e42fa;
  font-size: 14px;
  font-weight: 500;
  color: white;
  padding: 8px 20px;
  border-radius: 8px;
  border: none;
  &:hover{
    background-color: #fd4457;
  }
 
}

.modal-actions button[type="button"]:hover {
  background-color: #c82333;
  transform: scale(1.05);
}
