// .container {
//     display: grid !important;
//     grid-template-columns: repeat(2, 1fr) ;
// gap:30px !important;
// padding: 50px !important;
//     background: linear-gradient(to right, #8beee6, #1c8ed2);
//     width: 50% !important;
//     border: solid 2px black;
//     border-radius: 10px;
// }

// .submit-btn {
//     background: blue;
//     padding: 10px 35px;
//     text-align: center;
//     text-transform: uppercase;
//     color: white;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;

//     &:hover {
//         background: rgb(0, 0, 255, 0.7);
//     }
// }

// .row {
//     grid-column: span 1 !important;


// }
// .row2{
//     grid-column: span 2 !important;
//     }

// .content {

//     background: linear-gradient(to right top, rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234));
//     padding: 30px;
//     height: 100vh;


// }

// @media screen and (max-width: 1200px) {
//     .container {
//         width: 90% !important;
//         grid-template-columns: repeat(1, 1fr) !important;
//     }
//     .row {
//         grid-column: span 2 !important;
//     }

// }

.bizz-container {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    // background: linear-gradient(to right top, rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234));
}

.bizz-form {
   
    padding: 30px;
    border-radius: 8px;
    // background: linear-gradient(to right, #57a6d4, #aeefea);
    background-color: rgb(233, 230, 230);
    backdrop-filter: blur(4px);
    margin: 0 !important;
    width: 50vw !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

}

.bizz-form-group-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: span 1;
}

.bizz-form-group-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: span 2;
}

.bizz-form-group-label {
    width: 100%;
    text-align: left;
    color: black;
    font-size: 12px;

}

.bizz-form-group-input {
    width: 100%;
    padding: 8px;
    font-size: 10px !important;
    color:gray !important;
    border: none !important;
    border-radius: 8px;
    background-color: #57a6d4;
    
}
.bizz-form-group-input:focus{
    color:black !important;
}
.bizz-form-button-group {
    display: flex;
    justify-content: center;
    align-items: end;
    grid-column: span 2;
    font-size: 14px;
    font-weight: 500;

    button {
        padding: 6px 15px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        background: #4169E1;
        color: white;
    }
   
}
text::placeholder{
    color:gray
}



@media screen and (max-width: 1080px) {
    .bizz-form {
        width: 80vw !important;
        grid-template-columns: repeat(1, 1fr);

    }

    .bizz-form-group-two {
        display: flex;
     
        align-items: start;
        justify-content: start;
        grid-column: span 1;

    }

    .bizz-form-button-group {

        grid-column: span 1;

    }

}