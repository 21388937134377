.voiceModal {
  &.modal {
    font-family: "Inter", sans-serif;
    .modal-content {
      border-radius: 8px;
      border: 0;
      .modal-header {
        border: 0;
        border-bottom: 1px solid #D5D5D5;
        position: relative;
        background: #fff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        .modal-title {
          h4 {
            color: #000;
            font-size: 18px;
            font-weight: 500;
            margin: 0;
          }
          p {
            color: #8D8D8D;
            font-size: 14px;
            margin: 0;
          }
        }
      }
      .modal-body {
        .form-container {
          display: flex;
          gap: 20px; // Adjust the gap between the two sections as needed
          .form-input, .form-display {
            flex: 1; // Make both sections take equal space
            display: flex;
            flex-direction: column;
          }
          .form-input {
            textarea {
              flex: 1; // Make the textarea take up the remaining space
              font-size: 14px;
              height: 150px;
              outline: 0;
              padding: 10px;
              border-radius: 8px;
              border: 1px solid #ccc;
              box-shadow: none;
              // &::placeholder {
              //   color: #8D8D8D !important;
              // }
              &:hover, &:focus {
                outline: 0;
                box-shadow: none;
              }
              margin: 0 0 8px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .text-end {
              align-self: flex-end;
              .limit {
                font-size: 12px;
                color: #8D8D8D;
              }
            }
          }
          .form-display {
            display: flex;
            flex-direction: column;
            
          }
        }
      }
      .continue {
        // margin-left: 2px;
        background-color: #4169E1;
        border: 1px solid #4169E1;
        outline: 0;
        border-radius: 8px;
        color: white;
        padding: 15px 40px;
        &:hover {
          // background-color: #2e5ad7;
        }
      }
      .back {
        background-color: #fff;
        outline: 0;
        border: 1px solid #000;
        border-radius: 8px;
        color: #000;
        padding: 7px 20px;
        &:hover {
          background-color: #e9e9e9;
        }
      }
    }
  }
}
