$primary-color: #1d4ed8; /* Replace with your theme's primary color */
$gradient-bg: linear-gradient(to top right, #6ee7b7, #3b82f6, #a855f7);

.admin-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: $gradient-bg;

  .approval-modal {
    /* Add styles for ApprovalModal */
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding: 2rem 4rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 680px;
    .submit-btn{
        border-radius: 5px;
        background-color: #3178fc;
        color: white;
        padding: 8px 30px;
        margin-top: 5px;
        font-size: 14px;
      }
    h1 {
      font-size: 1.875rem;
      font-weight: 500;
      text-transform: capitalize;
    }

    p {
      margin-top: 0.5rem;
      font-size: 0.875rem;
      color: #6b7280; /* Tailwind's gray-500 */
    }

    .input-group {
      position: relative;
      width: 100%;

      label {
        font-size: 0.875rem;
        font-weight: 600;
      }

      input {
        width: 100%;
        padding: 0.5rem 2.5rem 0.5rem 2.5rem;
        background: #e5e7eb; /* Tailwind's gray-200 */
        border: 1px solid transparent;
        border-radius: 0.375rem !important;
        font-size: 0.875rem;

        &.error {
          border-color: #ef4444; /* Tailwind's red-500 */
          color: #ef4444;

          &::placeholder {
            color: #ef4444;
          }
        }
      }

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #6b7280; /* Tailwind's gray-500 */

        &.left {
          left: 0.75rem;
        }

        &.right {
          right: 0.75rem;
          cursor: pointer;
        }
      }
      .toggle-password{
        position: absolute;
        right:20px;
        top: 34px;
      }

      .error-message {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        padding: 0.75rem;
        border: 1px solid #ef4444;
        border-radius: 0.375rem;
        color: #ef4444;

        .close-icon {
          cursor: pointer;
          font-size: 1.5rem;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      width: 100%;

      a {
        font-size: 0.75rem;
        color: #10b981; /* Tailwind's green-600 */
        text-decoration: none;
      }
 
    //   button {
    //     background-color: blue;
    //     color: blue;
    //     padding: 0.5rem 2rem;
    //     font-weight: 500;
    //     border-radius:50px;

    //     &:hover {
    //       background: darken($primary-color, 10%);
    //     }

    //     &:disabled {
    //       background: lighten($primary-color, 20%);
    //       cursor: not-allowed;
    //     }
    //   }
    }
  }
}
